.group-section-title {
  font-size: 39px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 65px;
}

.our-rooms {
  margin-top: 100px !important;
}

.description-content {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.top-back-info {
  position: absolute;
  width: 100%;
  top: calc(45% + 4px);
  color: #fff;
  text-transform: uppercase;
}

.tag-line {
  font-size: 31px;
  padding: 15px;
  text-align: center;
  line-height: normal;
}

.property-description {
  margin-top: 65px;
  margin-bottom: 40px !important;
}

.map-image {
  max-height: 490px;
  max-width: 100%;
  display: -webkit-inline-box;
}

.contact-title {
  font-size: 40px;
  margin-bottom: 30px;
}

.contact-phone {
  margin-top: 30px;
}

.contact-email,
.contact-email:hover,
.contact-email:visited {
  color: #fff;
  cursor: pointer;
}

.contact-content {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.contact-content a,
.contact-content a:hover {
  color: #fff;
}

.responsive-info-panel a,
.responsive-info-panel a:hover {
  color: #fff;
}

.flickity-image {
  opacity: 0.2;
  margin: 0 30px;
  /*width: calc((100vw * 0.437) - 65px);*/
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.slide {
  height: auto;
  margin: 0 30px;
  position: relative;
  width: calc(43.75vw - 60px);
  opacity: 0.2;
}

.slide-content {
  height: 0;
  padding-bottom: 74.92%;
  position: relative;
  width: 100%;
  transition: opacity 0.45s ease;
}

.custom-slider {
  display: block;
}

.is-selected {
  opacity: 1 !important;
}

.attributes-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;
}

.attributes-container li {
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: center;
  text-align: center;
  width: 50%;
  border: 1px solid #efefef;
  list-style-type: none;
  position: relative;
  margin: 0 -1px -1px 0;
  padding: 17px 30px 18px;
}

.highlight-container {
  margin-left: -25px !important;
  display: flex;
  justify-content: center;
  margin-top: -12px;
}

.highlight-container li {
  list-style: none;
  font-size: 1rem;
}

.property-content {
  margin-top: 100px;
}

.property-attributes {
  flex-grow: 1;
}

.custom-slider {
  display: block;
}

.responsive-slider {
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  display: none;
  height: 0;
  padding-bottom: 74%;
  position: relative;
  width: 100%;
}

.responsive-slider .slider {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.custom-property-section {
  display: block;
}

.responsive-property-section {
  display: none !important;
}

.room-type-section {
  display: grid;
  padding-top: 50px;
  padding-left: 50px;
  margin-top: 30px;
  padding-right: 50px;
  max-width: 1920px;
  margin: 0 auto 60px;
  max-width: 1920px;
  position: relative;
  width: 100%;
}

.room-type-column {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.show-more-rooms-button-content {
  padding-left: 20px;
  padding-right: 20px;
}

.show-more-rooms-button,
.show-more-rooms-button:hover,
.show-more-rooms-button:focus {
  margin-top: 40px;
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  text-align: center;
  padding: 40px;
  color: #000;
  cursor: pointer;
  font-size: 19px;
}

.policies-title {
  font-size: 20px;
  padding-left: 23px;
  margin-top: 50px;
  margin-bottom: 25px;
}

.social-media-content {
  margin-top: 30px;
  font-size: 20px !important;
}

.policies-content {
  height: calc(100% - 145px);
  overflow-y: scroll;
}

.policies-back,
.policies-back:hover {
  display: none;
  color: #000 !important;
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: -21px;
}

@media (max-width: 1200px) {
  .tag-line {
    font-size: 18px !important;
    margin-top: 18px !important;
  }

  .highlight-container {
    display: none !important;
  }

  .section-title {
    font-size: 26px !important;
    margin-top: 35px !important;
    margin-bottom: 45px !important;
  }

  .responsive-slider {
    display: block;
  }

  .custom-slider {
    display: none;
  }

  .custom-property-section {
    display: none !important;
  }

  .responsive-property-section {
    display: block !important;
  }

  .property-content {
    margin-top: 50px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .attributes-container {
    margin-left: -25px !important;
  }

  .contact-title {
    font-size: 25px;
    margin-top: 10px;
  }

  .room-type-section {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .show-more-rooms-button-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .show-more-rooms-button,
  .show-more-rooms-button:hover,
  .show-more-rooms-button:focus {
    padding: 20px !important;
    font-size: 15px !important;
    margin-bottom: -55px !important;
  }

  .policies-content {
    height: 100% !important;
  }

  .policies-back {
    display: block !important;
  }
}
