.menu {
  border: none !important;
  margin-top: 14px !important;
  box-shadow: none !important;
}

.ui.menu .item:before {
  border: none !important;
  background: none !important;
}

.weather {
  margin-top: 7px;
}

.top {
  padding: 15px;
  height: 85px;
  display: flex;
}

.menu-responsive {
  display: none;
  color: #000 !important;
  font-size: 22px !important;
  position: absolute;
  top: 22px;
  z-index: 9;
}

.property-logo-name,
.property-logo-name:hover {
  font-size: 22px;
  color: #000;
  padding-top: 21px;
  width: 100%;
  text-transform: uppercase !important;
  font-weight: 500;
}

.menu-grid {
  width: 100% !important;
}

.menu-grid-items {
  position: absolute !important;
  right: -10px !important;
}

@media (max-width: 991px) {
  .menu {
    display: none !important;
  }

  .weather {
    display: none !important;
  }

  .top {
    display: block !important;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 9999;
    border-bottom: 1px solid #ccc;
  }

  .menu-responsive {
    display: block !important;
  }

  .menu-grid {
    height: 94px;
  }

  .property-logo-name,
  .property-logo-name:hover {
    position: absolute;
    font-size: 19px !important;
    width: 100%;
    width: 139%;
    text-align: left;
  }

  .menu-grid-items {
    position: initial !important;
  }
}
